.search-box{
    position: relative;
    width: 100%;
    margin-top: 10px;
    #search{
        width: 100%;
        padding: 10px;
        border-radius: 15px;
        outline: none;
        
    }
    #search-icon{
        position: absolute;
        right: 20px;
        top: 16px;
    }
}
.income{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    row-gap: 50px;
    gap: 10px;
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid greenyellow;
        padding: 15px;
        // max-width: 300px;
        gap: 20px;
    
        .daily-income{
            display: flex;
            flex-direction: column;
            input{
                padding: 5px;
                border-radius: 5px;
            }
        }
        button{
            width: 150px;
            padding: 10px;
            border-radius: 5px;
            background-color: greenyellow;
            border: none;
            &:hover{
                transform: scale(1.1);
                transition: ease-in 0.3s;
            }
        }
    }
}
// .pie_table{
//     display: flex;
//     align-items: center;
//     gap: 20px;
//     .pie{
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: 50%;
//         height: 400px;
//         flex-direction: column;
//     }
//     #tab{
//         width: 50%;
//     }
    
// }
@media(max-width:455px){
    .income{
        grid-template-columns: 1fr;
    }
}
