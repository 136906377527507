.btnrow {
  margin-top: 50px !important;
  background-color: red;
}
.date-search{
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 10px 20px;
  align-items: center;

  .dates{
      display: flex;
      gap: 20px;
      .start-date{
          display: flex;
          gap: 10px;
          align-items: center;
          input{
              border: none;
              background: linear-gradient(to right, rgb(247, 236, 25), rgb(240, 31, 31));
              padding: 5px;
              border-radius: 5px;
          }
         
          h5{
              font-size: 15px;
              margin-bottom: unset !important;
          }
      }
  }
  button{
      width: "50%";
      height: 35px;
      border: none;
      border-radius: 5px;
      background: linear-gradient(to right, rgb(247, 236, 25), rgb(240, 31, 31));
    }
}
.download-but{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button{
    width: 110px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(to right, rgb(247, 236, 25), rgb(240, 31, 31));
  }
}
@media screen and (max-width: 480px) {
  .Fontresponsive {
    background-color: aqua;
  }
}
