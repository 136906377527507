.status-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns for each container */
    gap: 10px; /* Space between items */
    
    .status-active,
    .status-inactive,
    .income-generated {
      display: flex;
      flex-direction: column;
      padding: 10px; /* Adjusted padding */
      height: 80px; /* Adjusted height */
      width: 100%; /* Make sure containers stretch to fit */
      box-sizing: border-box; /* Ensure padding is included in width/height */
      border-radius: 10px;
      
      h6 {
        margin-bottom: 8px; /* Reduced margin */
        font-size: 16px; /* Adjusted font size */
      }
      
      h5 {
        font-size: 14px; /* Adjusted font size */
      }
    }
    
    .status-active {
      background: linear-gradient(45deg, yellow, orange);
    }
    
    .status-inactive {
      background: linear-gradient(45deg, orange, yellow);
    }
    
    .income-generated {
      background-color: #93d1ee;
    }
  }
  
  .filter-box {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;
    
    select {
      width: 100px;
      height: 30px;
      border-radius: 5px;
      background-color: #f59357;
    }
  }
  
  @media (max-width: 1200px) {
    .status-box {
      grid-template-columns: repeat(2, 1fr); /* Adjusted for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .status-box {
      grid-template-columns: 1fr; /* Stack items vertically on smaller screens */
      gap: 10px; /* Space between items */
    }
  }
  
  @media (max-width: 500px) {
    .status-box {
      grid-template-columns: 1fr; /* Stack items vertically on very small screens */
      gap: 5px; /* Further reduced gap */
      
      .status-active,
      .status-inactive,
      .income-generated {
        padding: 8px; /* Further reduced padding */
        height: 60px; /* Further adjusted height */
        width: 100%; /* Full width */
        
        h6 {
          font-size: 12px; /* Further adjusted font size */
        }
        
        h5 {
          font-size: 10px; /* Further adjusted font size */
        }
      }
    }
  }
  