.login-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f0f2f5; /* Optional: to provide a background color */
}

.signinstyle {
  padding: 30px !important;
  background-color: #fff; /* White background for the form */
  box-shadow: 0 4px 8px rgba(10, 10, 10, 0.1); /* Box shadow */
  border-radius: 10px; /* Optional: to make the corners rounded */
  max-width: 600px; /* Optional: to control the width of the form */
  width: 100%; /* Make it responsive */
}

.redlogo {
  margin-bottom: 40px;
}

.linestyless {
  width: 35%;
  height: 1px;
  margin-left: 32%;
  background-color: #eae8ed;
}

.colorforgot {
  color: #b8b5bb !important;
}

.background-image-container {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.icon-input-login {
  position: absolute;
  left: 15px;
  top: 10px;

  img {
    height: 20px;
  }
}
.login-input {
  border-radius: 20px;
  height: 40px;
  padding-left: 80px;
  width: 100% !important;

}
.input-wrapper-login {
  position: relative;
  width: 100%;
}
.login-wrapper-inner {
  padding: 0 100px;
}

@media all and (max-width: 480px) {
  .signinstyle {
    padding: 30px !important;

    width: 100%; /* Make it responsive */
  }

  .login-wrapper-inner {
    padding: 0 0px;
  }
}


/* SignIn.css */

/* Eye icon styles */
.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-toggle-icon svg {
  font-size: 2.2rem;
  color: #aaa;
}

.password-toggle-icon:hover svg {
  color: #333;
}

.passwordcreate-toggle-icon {
  position: absolute;
  right: 10px;
  top: 68%;
  transform: translateY(-50%);
  cursor: pointer;
}
.passwordcreate-toggle-icon svg {
  font-size: 2.2rem;
  color: #aaa;
}

.passwordcreateppd-toggle-icon {
  position: absolute;
  right: 10px;
  top: 93%;
  transform: translateY(-50%);
  cursor: pointer;
}
.passwordcreateppd-toggle-icon svg {
  font-size: 2.2rem;
  color: #aaa;
}
.center-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

