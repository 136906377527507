.bggray {
  background-color: var(--bg-color);
}
.custom-table th {
  color: var(--th-color);
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.custom-table td {
  color: var(--td-color);
  font-size: 14px;
  padding: 15px;
}
.table-group-divider-custom {
  color: var(--text-primary);
  // border-width: 3px;
}

//Icon
.custom-switch {
  background-color: white;
  border-color: #d4cccc;
}
.custom-switch:checked {
  background-color: #7e57c2;
  border-color: white;
}
.switchIcon {
  height: 15px;
}

.edit_icon {
  color: #5e5e5e;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.greendar {
  color: green;
  text-transform: capitalize;
}

.activetr {
  background-color: #daceee;
  border: 2px solid #9b87bf;
  color: #7e57c2;
  border-radius: 10px;
  height: 30px;
  padding: 0 20px;
  text-align: center;
p{
  margin-bottom: 0;

}
}
.roletxt {
  color: var(--th-color);
  font-size: 12px;
  // font-weight: 500;
}
.downtxt {
  color: var(--td-color);
  font-size: 10px;
}
.iconhw {
  height: 25px;
  width: 25px;
}
.bgWhite {
  background-color: white;
}

.bordertablesho {
  border: 2px solid #ffffff;
}
.line2px {
  border-bottom: 0px solid #2a78c7;
  padding: 0 5px 0 5px;
}
.line1px {
  border-bottom: 1px solid #dee2e6;
  padding: 0 5px 0 5px;
}
.line2px-green {
  border-bottom: 3px solid orange;
  padding: 0 5px 0 5px;
}
.btn-stylevio {
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: var(--btn-bg);
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px;
}
.iconhw-notepad {
  cursor: pointer;
  height: 27px;
  width: 30px;
  color: #fff;
  background-color: var(--btn-bg);
  border-radius: 50px;
  padding: 3px;
}
.small_icon {
  background-color: #88df8f;
  padding: 3px;
  border-radius: 50px;
  color: #2b6930;
  border: 1px solid RGBA(27, 94, 32, 1);
  width: 23px;
  height: 23px;
}
.datetime {
  font-size: 12px;
}
.sidecontent {
  overflow-y: auto;
  height: 100vh;
  background: linear-gradient(to bottom, rgb(247, 236, 25), rgb(240, 31, 31));
}
.form-check-input {
  width: 12px;
  height: 12px;
  border-color: black;
}
.tgray {
  background-color: #f3f3f3;
  background-color: #de8d8d;
}
.tgray33 {
  background-color: #f3f3f3;
  // background-color: #de8d8d;
}
.form-check {
  // background-color: #2b6930;
}
