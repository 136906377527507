.coupon-select{
    max-width: 700px;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    border: 1px solid orange;
    border-radius: 5px;
    button{
        padding: 20px;
        border: unset;
        border-left: 1px solid orange;
        border-radius: 0px 5px 5px 0px;
        background-color: orange;
        &:hover{
           background-color: rgb(249, 181, 55);
            transition: ease 0.5s;
        }
    }
}
.coup-dow{
    max-width: 700px;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
    border-radius: 5px;
    
        button{
            padding: 20px;
            border: unset;
            border-left: 1px solid orange;
            border-radius: 5px;
            background-color: orange;
            &:hover{
                transform: scale(1.1);
                transition: ease 0.5s;
            }
        
         }
}
#ban-image{
    width: 100%;
    height: 300px;
    margin-top: 10px;
}
@media(max-width:900px){
    #ban-image{
        width: 100%;
        height: 250px;
        margin-top: 10px;
    }
}
@media(max-width:485px){
    #ban-image{
        width: 100%;
        height: 200px;
        margin-top: 10px;
    }
    #gen-but{
        padding: 20px 5px;

    }
}
